import { Navigate } from 'react-router-dom';

import Notification from './notification';

const ErrorNotification = (error) => {
  if (!error?.response?.data?.message && !error?.message) {
    Notification('Something went wrong!', 'success');
  } else if (error?.response?.data?.message === 'Session Expired') {
    localStorage.clear();
    <Navigate to="/login" />;
  } else {
    Notification(error?.response?.data?.message || error?.message, 'error');
  }
};

export default ErrorNotification;
