import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Logo from '../components/logo';
import Page from '../components/Page';
import Notification from '../utils/notification';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function Redirect() {
  const navigate = useNavigate();

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);

    const status = queryParameters.get('status');
    const token = queryParameters.get('token');
    const name = queryParameters.get('name');

    if (status === '200') {
      localStorage.setItem('at', token);
      localStorage.setItem('name', name);
      navigate('/dashboard/app');
    } else {
      navigate('/login');
      Notification('Something went wrong!', 'error');
    }
  }, [navigate]);

  return (
    <>
      <Page title="Redirect" />
      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
            <Typography variant="subtitle1" gutterBottom>
              Logging you in...
            </Typography>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
