import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

import { isValidToken } from '../utils/jwt';

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const token = localStorage.getItem('at');
  const { pathname } = useLocation();

  if (!isValidToken(token)) {
    return <Navigate to="/login" state={pathname} />;
  }

  return <>{children}</>;
}
