// @mui
import PropTypes from 'prop-types';
import { Box, Stack, Link, Card, Divider, Typography, CardHeader } from '@mui/material';

import Scrollbar from '../../../components/scrollbar';
import { fNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppNewsUpdate({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {list.map((news) => (
            <NewsItem key={news.id} news={news} />
          ))}
        </Stack>
      </Scrollbar>

      <Divider />
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    name: PropTypes.string,
    objective: PropTypes.string,
    reach: PropTypes.number,
  }),
};

function NewsItem({ news }) {
  const { name, objective, reach } = news;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Link color="inherit" variant="subtitle2" underline="hover" noWrap>
          {name}
        </Link>

        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {objective}
        </Typography>
      </Box>

      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0 }}>
        {fNumber(reach)} <span style={{ marginLeft: '6px', color: 'text.secondary' }}>reached</span>
      </Typography>
    </Stack>
  );
}
