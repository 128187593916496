import { useMutation, useQuery } from '@tanstack/react-query';

import { BaseAPI } from '../utils/api';
import ErrorNotification from '../utils/errorNotification';

export const GetAccounts = () =>
  useQuery(
    ['accounts'],
    () =>
      BaseAPI.get('/analytik/accounts', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('at')}`,
        },
      }),
    {
      select: (data) => data?.data?.data,
      onError: ErrorNotification,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

export const GetDashboardInformation = () =>
  useMutation(
    (body) =>
      BaseAPI.post('/analytik/list', body, { headers: { Authorization: `Bearer ${localStorage.getItem('at')}` } }),
    {
      onError: ErrorNotification,
      select: (data) => data?.data?.data,
    }
  );
