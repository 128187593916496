import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Box,
  Stack,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  DialogContentText,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import Iconify from '../components/iconify';
import {
  AppNewsUpdate,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  AppCostActionType,
  AppMultipleCostChart,
  TwoColumnBarChart,
} from '../sections/@dashboard/app';
import Page from '../components/Page';
import { GetAccounts, GetDashboardInformation } from '../ApiCalls/dashboard';

export default function DashboardAppPage() {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [account, setAccount] = useState({ id: '', name: '' });
  const [dashboardInfo, setDashboardInfo] = useState();

  const { data: accounts, isLoading: gettingAccounts } = GetAccounts();
  const { mutate: getDashboardInfo, isLoading: gettingDashboardInfo } = GetDashboardInformation();

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleDateRangeChange = (value) => {
    setDateRange(value);
  };

  const handleAccountChange = (e) => {
    const selectedAccountName = accounts?.find((acc) => acc.id === e.target.value)?.name || '';
    setAccount({ id: e.target.value, name: selectedAccountName });
  };

  const handleSearch = () => {
    const body = {
      id: account.id,
      from_date: moment(dateRange[0]).format('YYYY-MM-DD'),
      to_date: moment(dateRange[1]).format('YYYY-MM-DD'),
    };

    getDashboardInfo(body, {
      onSuccess: (data) => {
        setDashboardInfo(data?.data?.data);
        setOpen(false);
      },
    });
  };

  return (
    <>
      <Page title="Dashboard" />

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box
              sx={{
                backgroundColor: theme.palette.grey[200],
                py: 1,
                px: 1.4,
                borderRadius: 2,
                cursor: 'pointer',
                transition: '.5s ease',
                border: '2px solid transparent',
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.light, 0.23),
                  border: `2px solid ${theme.palette.primary.main}`,
                },
              }}
              onClick={() => setOpen(true)}
            >
              <Stack direction="row">
                <Stack direction="row">
                  <Typography variant="body2">Account: </Typography>
                  <Typography variant="body2" color="primary">
                    &nbsp; {account.name}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <Divider orientation="vertical" sx={{ margin: '0 10px' }} />
                  <Typography variant="body2">
                    {moment(dateRange[0]).format('YYYY-MM-DD')} to {moment(dateRange[1]).format('YYYY-MM-DD')}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>

          {dashboardInfo?.totalMoneySpent ? (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  title="Total Money Spend"
                  total={dashboardInfo?.totalMoneySpent}
                  icon={'ic:twotone-wallet'}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  title="Total Impressions"
                  total={dashboardInfo?.totalImpression}
                  color="success"
                  icon={'ic:twotone-important-devices'}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  title="Total Reach"
                  total={dashboardInfo?.totalReach}
                  color="warning"
                  icon={'ic:twotone-spatial-audio'}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  title="Total Clicks"
                  total={dashboardInfo?.totalClicks}
                  color="error"
                  icon={'ic:twotone-ads-click'}
                />
              </Grid>

              {/* click stats */}
              <Grid item xs={12} md={6} lg={4}>
                <AppCurrentVisits
                  title="Click Stats"
                  chartLabels={dashboardInfo?.pieChartData?.labels}
                  chartSeries={dashboardInfo?.pieChartData?.series}
                  chartColors={[
                    theme.palette.primary.main,
                    theme.palette.warning.main,
                    theme.palette.info.main,
                    theme.palette.error.main,
                  ]}
                />
              </Grid>

              {/* cost per action type */}
              <Grid item xs={12} md={6} lg={4}>
                <AppCostActionType
                  title="Value per action type"
                  chartSeries={dashboardInfo?.actionPieChartData?.series}
                  chartLabels={dashboardInfo?.actionPieChartData?.labels}
                  chartColors={[
                    theme.palette.primary.main,
                    theme.palette.info.main,
                    theme.palette.warning.main,
                    theme.palette.error.main,
                  ]}
                />
              </Grid>

              {/* video stats */}
              <Grid item xs={12} md={6} lg={4}>
                <AppMultipleCostChart
                  title="Video Stats"
                  chartLabels={dashboardInfo?.donutChartData?.labels}
                  chartSeries={dashboardInfo?.donutChartData?.series}
                  chartColors={[
                    theme.palette.primary.main,
                    theme.palette.warning.main,
                    theme.palette.info.main,
                    theme.palette.error.main,
                  ]}
                />
              </Grid>

              <Grid item xs={12}>
                <TwoColumnBarChart
                  title="Spend Vs Reach"
                  chartLabels={dashboardInfo?.barChartData?.categories}
                  chartSeries={[
                    { name: 'Spend', data: dashboardInfo?.barChartData?.series[0].data },
                    { name: 'Reach', data: dashboardInfo?.barChartData?.series[1].data },
                  ]}
                />
              </Grid>

              {/* Spend, impression and click */}
              <Grid item xs={12}>
                <AppWebsiteVisits
                  title="Click and reach based on spent money"
                  chartLabels={dashboardInfo?.combinationChartData?.categories}
                  chartData={[
                    {
                      name: 'Spent',
                      type: 'column',
                      fill: 'solid',
                      data: dashboardInfo?.combinationChartData?.series[0].data,
                    },
                    {
                      name: 'Impression',
                      type: 'area',
                      fill: 'gradient',
                      data: dashboardInfo?.combinationChartData?.series[1].data,
                    },
                    {
                      name: 'Clicks',
                      type: 'line',
                      fill: 'solid',
                      data: dashboardInfo?.combinationChartData?.series[2].data,
                    },
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={8}>
                <AppNewsUpdate
                  title="Top 5 reached ad"
                  list={dashboardInfo?.top5Ads?.map((ad) => ({
                    id: faker.datatype.uuid(),
                    name: ad.ad_name,
                    objective: ad.objective,
                    reach: ad.reach,
                  }))}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h5" gutterBottom>
                  Sorry!! Data not found
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>

        {/* modal */}
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
          <DialogTitle>
            Select account and date range{' '}
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Iconify icon="eva:close-outline" sx={{ height: '30px', width: '30px' }} />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ marginBottom: '1em' }}>
            {gettingAccounts ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <DialogContentText>
                  Date range and account ID are important parameters for getting Facebook ad data because they help to
                  narrow down the scope of the data being retrieved, making it more targeted and relevant to the user's
                  needs.
                  <br />
                  <Typography variant="button">N.B. &nbsp; Please disable adblock if you have any</Typography>
                </DialogContentText>
                <Stack spacing={3} sx={{ mt: 4 }}>
                  <TextField select fullWidth label="Ad account" onChange={handleAccountChange} value={account.id}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {accounts?.map((account) => (
                      <MenuItem key={account.id} value={account.id}>
                        {account.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <DateRangePicker
                    calendarIcon={null}
                    rangeDivider="to"
                    onChange={handleDateRangeChange}
                    value={dateRange}
                  />
                  <LoadingButton fullWidth variant="contained" onClick={handleSearch} loading={gettingDashboardInfo}>
                    Search
                  </LoadingButton>
                </Stack>
              </>
            )}
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
}
