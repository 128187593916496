import { string } from 'prop-types';
import { Helmet } from 'react-helmet-async';

const Page = ({ title }) => (
  <Helmet>
    <title> {title} | Analytics </title>
  </Helmet>
);

export default Page;

Page.propTypes = {
  title: string,
};
