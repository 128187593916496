import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notification = (message, type) => {
  toast[`${type}`](`${message}`, {
    position: 'bottom-left',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};

export default Notification;
