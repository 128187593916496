import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Box, Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

TwoColumnBarChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartSeries: PropTypes.array.isRequired,
  chartLabels: PropTypes.array.isRequired,
};

export default function TwoColumnBarChart({ title, subheader, chartSeries, chartLabels, ...other }) {
  const chartOptions = useChart({
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => '',
        },
      },
    },
    plotOptions: {
      bar: { horizontal: false, barHeight: '28%', borderRadius: 2 },
    },
    xaxis: {
      categories: chartLabels,
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ mx: 3 }}>
        <ReactApexChart type="bar" series={chartSeries} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
