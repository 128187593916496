import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Container, Typography, Stack } from '@mui/material';

import Logo from '../components/logo';
import Iconify from '../components/iconify';
import Page from '../components/Page';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
  const url = `${process.env.REACT_APP_BASE_URL}/auth/facebook`;

  const [loading, setLoading] = useState(false);

  const handleLoginBtnClick = () => {
    window.location.href = url;
    setLoading(true);
  };

  return (
    <>
      <Page title="Login" />
      <div className="bg">
        <StyledRoot>
          <Logo
            sx={{
              position: 'fixed',
              top: { xs: 16, sm: 24, md: 40 },
              left: { xs: 16, sm: 24, md: 40 },
            }}
          />

          <Container maxWidth="sm">
            <StyledContent>
              <Typography variant="h4" gutterBottom>
                Analytik: Your next analytics
              </Typography>

              <Stack direction="row" spacing={2}>
                <LoadingButton
                  fullWidth
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={handleLoginBtnClick}
                  loading={loading}
                >
                  <Iconify icon="eva:facebook-fill" color="#fff" width={22} height={22} />
                  Login With Facebook
                </LoadingButton>
              </Stack>
            </StyledContent>
          </Container>
        </StyledRoot>
      </div>
    </>
  );
}
